import React, { useState, useEffect } from 'react';
import { Layout, Button, Form, Input, message } from 'antd';
import axios from 'axios';
import "./style.css"

const { Content } = Layout;

const App = () => {

  useEffect(() => {
    axios.post('https://hello.uzi666.top/api/user/auth')
    .then((response)=>{
      if(response.data.code === '200'){
        window.location.href = '/view/';
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }, []);

  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(''); 
  const [password, setPassword] = useState('');
  const handleLogin = () => {

    setLoading(true);

    if (!phoneNumber) {
      message.error('请输入手机号');
      setLoading(false);
      return;
    }

    if (!password) {
      message.error('请输入密码');
      setLoading(false);
      return;
    }
   const messageLoading =  message.loading('正在登录中',0);
   const showMessage = (type, content) => {
    setLoading(false);
    messageLoading();
    message[type](content);
   }
  //  手机号和密码
   const data = {
    field:{
      phone:phoneNumber,
      password:password
    }
   }
  //  发送登录请求
    axios.post('https://hello.uzi666.top/api/user/login',data)
    .then((response)=>{
      if(response.data.code === 200){
        showMessage('success',response.data.msg);
        // 登录成功跳转
        setTimeout(() => {
          window.location.href = '/view/';
        }, 2000);
      }else{
        showMessage('error',response.data.msg);
      }
    })
    .catch((error)=>{
      console.log(error);
     showMessage('error','网络异常');
    })

  };

  // 注册
  const handleSign = () => {
    window.location.href = 'sign';
  }

  return (
    <Layout  className='layout'>
      <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Form style={{ width: '320px' }}>
          <Form.Item >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src="https://hello.uzi666.top/static/images/logo.png" alt="Logo" style={{ height: '60px', width: '60px', marginRight: '25px' , marginLeft:'10px'}} />
              <p className='title'>数据统计系统</p>
            </div>
            <Input  className='inputStyle' onChange={(e)=>setPhoneNumber(e.target.value)} placeholder="请输入手机号" />
          </Form.Item>
          <Form.Item >
            <Input.Password className='inputStyle' onChange={(e)=>setPassword(e.target.value)} placeholder="请输入密码" />
            <span style={{ float: 'right', cursor: 'pointer', marginTop: '15px', color: "#333" }} onClick={handleSign}>注册账户</span>
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              className='loginButton'
              loading={loading}
              onClick={handleLogin} 
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
};

export default App;
